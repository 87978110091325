@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;700&display=swap');
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');

* {
    outline: none;
    font-family: 'Jost', sans-serif; }

body, html {
    padding: 0;
    margin: 0; }

#root {
    width: 100vw;
    height: 100vh;
    background: var(--start-screen-bg-color); }

.ol-zoom {
    right: 8px;
    left: inherit !important; }

p {
    margin: 0; }

a, a:hover {
	text-decoration: none; }
