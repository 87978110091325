.sidebar {
    width: 460px;
    height: 100%;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: 4px 0px 15px #00000029;
    overflow-y: auto;

    .sidebar-wrapper {
        padding: 20px;

        hr {
            margin-bottom: 30px; }

        h1 {
            display: inline-block;
            font-weight: 700;
            margin-top: 0px;
            margin-bottom: 10px;
            span {
                font-weight: 300; } } } }

.theme-toggle {
  .switch {
    display: flex;
    height: 36px;
    position: relative;
    width: 70px;
    cursor: pointer;

    input {
      display: none; } }

  .slider {
    background-color: #ececec;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;

    &:before {
      background-color: #333;
      border: 1px solid #000;
      bottom: 3px;
      content: "";
      height: 26px;
      left: 4px;
      position: absolute;
      transition: .4s;
      width: 26px;
      z-index: 421; } }

  input:checked + .slider:before {
    transform: translateX(34px);
    border-color: #CFCFCF;
    background-color: #EFEFEF; }

  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%; } }

  .toggle-moon {
    width: 50%;
    text-align: center;
    padding: 0.25em;
    position: relative;
    z-index: 420; } }

.toggle-sun {
  width: 50%;
  text-align: center;
  padding: 0.25em;
  position: relative;
  z-index: 420; }

.input-text {
    width: -webkit-fill-available;
    width: -moz-available;
    border: 0;
    padding: 15px;
    background: #ececec;
    margin-top: 5px; }

.input-container {
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex; }

.input-text-slider {
    border: 0;
    padding: 15px;
    background: #ececec;
    margin-left: 15px;
    position: absolute;
    width: 74px;
    height: 74px;
    box-sizing: border-box; }

.range-slider {
    width: 100%; }

.slider-container {
    position: relative;
    width: 72%;
    display: flex;
    align-items: center;
    height: 74px; }

.color-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline; }

.color-input {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 20px;

    .color-input-item {
        width: 27%;
        border: 5px solid #eee;
        margin: 5px;
        height: 40px;
        display: inline-block;
        cursor: pointer;
        position: relative;

        .color-input-item-overlay {
            width: 100%;
            height: 40px;
            background: transparent;
            position: absolute;
            top: 0;
            left: 0; }

        .color-input-item-close {
            top: -15px;
            right: -10px;
            position: absolute;
            z-index: 2;
            background: white;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 2px 4px #0000004f;

            svg {
                width: 10px; } } } }

.fa.iconButton {
    width: 30px;
    height: 18px;
    color: #FFF;
    background-color: #000;
    padding: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 6px; }

#json-pretty > pre {
    height: 13rem;
    padding: 0.5rem;
    overflow: auto; }

#json-pretty > pre::-webkit-scrollbar {
  width: 9px; }

#json-pretty > pre::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); }

#color-format {
  border: none;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  padding: 4px; }

#color-format:hover {
  cursor: pointer; }

.copy-btn {
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  float: right;
  padding: 5px;
  width: 4rem; }

.copy-btn:active {
    opacity: 0.5; }

.copy-btn:hover {
    cursor: pointer; }

.theme-switch-wrapper {
  display: inline;
  float: right;
  align-items: center;
  margin-top: 2%; }

@media only screen and (max-width: 1440px) {
    .sidebar {
        width: 400px; } }

@media only screen and (max-width: 1366px) {
    .sidebar {
        width: 380px; } }

@media only screen and (max-width: 1280px) {
    .sidebar {
        width: 360px; } }
