.main-map {
    height: 100vh; }

.location-btn {
    position: fixed;
    top: 4rem;
    right: 0.5rem;
    z-index: 1;
    margin-top: 0.5rem; }

.gcd-gl-control.ol-control {
    padding: 0;
    width: 20rem; }

.ol-geocoder {
    position: absolute;
    top: 1.5%;
    left: 55%; }

.ol-control {
    display: flex; }

.ol-zoom.ol-unselectable.ol-control {
    display: block; }

#gcd-input-query.gcd-gl-input {
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    opacity: 0.6;
    padding: 0.5rem;
    width: 20rem; }

#gcd-button-control.gcd-gl-btn {
    display: none; }

.gcd-gl-result {
    list-style: none;
    margin-top: 20%;
    background-color: #eee;
    opacity: 0.5;
    font-weight: 800;
    padding: 0;
    overflow: hidden;
    overflow-y: scroll;
    height: 200px;
    width: 20rem;

    li {
        margin: 0;
        margin-bottom: 0.5rem; }

    .gcd-road {
        color: black; }

    li:hover {
        cursor: pointer;
        background: #ccc; } }

.hide-results {
    display: none; }

.ol-popup {
  position: absolute;
  bottom: 12px;
  right: 12px;
  float: right;
  min-width: 280px;
  text-align: right;

  .powered {
    padding: 5px;
    font-size: 13px;

    a {
      color: rgb(45, 124, 241);
      font-weight: 700; } }

  .badges {
    display: flex;
    justify-content: flex-end; }


  img {
    height: 1.45rem;
    margin: 0px 5px; }

  a {
    text-decoration: none; } }
